package com.ustadmobile

import kotlin.String

internal object BuildConfigJs {
  internal const val APP_VERSION: String = "0.4.135"

  internal const val APP_PRESET_LOCALE: String = "tg"

  internal const val APP_UI_LANGUAGES: String = "tg,ru,en"

  internal const val APP_UI_GOPTS: String = "1,2"

  internal const val APP_UI_SHOW_POWERED_BY: String = "false"
}
